import { required } from "@vuelidate/validators";
import { maxLength } from "vuelidate/lib/validators";
export default class TypeRemoval {
    constructor() {
        this.id = null;
        this.initials = null;
        this.description = null;
    }
    validations() {
        return {
            initials: {
                required,
                maxLength: maxLength(45),
            },
        };
    }
}